import { useRef, useState } from "react";
import Menubar from "../../../components/menubar";
import Head from "../../../components/menubar/head";
import DocumentTitle from "react-document-title";
import "./invest.scss";
import { invest_api, logOut } from "../../../services/api";

export function Invest() {
  sessionStorage.setItem("mainmenu", 3);
  if (sessionStorage.getItem("systemmenu")) {
    sessionStorage.removeItem("systemmenu");
  }
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [ShowMenu, setShowMenu] = useState(false);
  window.addEventListener("resize", () => {
    setBrowserWidth(window.innerWidth);
  });
  const showmenu = (e) => {
    setShowMenu(e);
  };

  const icon = ["fa-fire-flame-curved", "fas fa-bolt", "fa-solid fa-gem"];
  const plan = ["Basic", "Professional", "Ultimate"];
  const rate = ["10.8%", "15.7%", "23.5%"];
  const filter = ["Most popular", "Recommended", "Best Value"];
  const min = ["$100", "$1000", "$5000"];
  const max = ["$1000", "$5000", "unlimited"];
  const list = [
    [1, 1, 1, 1, 0],
    [1, 1, 1, 1, 0],
    [1, 1, 1, 1, 1],
  ];

  return (
    <DocumentTitle title="Newdaawn | Invest">
      <div className="invest-page">
        <Head showMenu={showmenu} />
        {browserWidth > 0 && <Menubar showMenu={ShowMenu} />}
        <div className="invest-container">
          <div className="invest-head">Choose Your Plan</div>
          <div className="invest-card-container">
            {plan.map((plans, i) => (
              <InvestCard
                icon={icon[i]}
                plan={plan[i]}
                rate={rate[i]}
                filter={filter[i]}
                duration={"per week"}
                min={min[i]}
                max={max[i]}
                list={list[i]}
              />
            ))}
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}

export function InvestCard({
  plan,
  rate,
  filter,
  duration,
  list,
  min,
  max,
  icon,
}) {
  const lists = list.map((e, i) => {
    return e === 1 ? "fa-check" : "fa-xmark";
  });

  const details = [
    `Minimum deposit ${min}`,
    `Maximum deposit ${max}`,
    `Customer support 24/7 `,
    `Instant withdrawal `,
    `Rewards/Bonus `,
  ];
  const [amount, setAmount] = useState("");
  const investBtn = useRef(null);
  const checkLength = (e) => {
    setAmount(e.target.value);
    if (
      e.target.value >= Number(min.slice(1)) &&
      e.target.value <= Number(max.replace(/unlimited/g, 999e99).slice(1))
    ) {
      investBtn.current.id = "";
    } else {
      investBtn.current.id = "disabled";
    }
  };

  const investFunc = async () => {
    console.log(amount, plan);
    try {
      const resp = await invest_api(amount, plan);

      if (resp) {
        alert(resp.data.message);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logOut();
      } else {
        alert(error.response.data.message);
      }
    }
  };

  return (
    <div className={`invest-card ${plan}`}>
      <div className="invest-card-first">
        <i class={`fas ${icon} fa-beat`}></i>
      </div>
      <div className="invest-card-second">
        <div className="invest-card-plan">{plan}</div>
        <div className="invest-card-rate">{rate}</div>
      </div>
      <div className="invest-card-third">
        <div className="invest-card-filter">{filter}</div>
        <div className="invest-card-duration">{duration}</div>
      </div>
      <div className="invest-card-desc">
        {lists.map((x, j) => (
          <div className="invest-card-desc-item">
            <span className={`fa-solid  ${x}`}></span>
            <span>{details[j]}</span>
          </div>
        ))}
      </div>
      <div className="invest-card-amount">
        <span>Amount To Invest</span>
        <input
          type="number"
          name="amount"
          id="invest-card-input"
          onChange={checkLength}
          placeholder="Enter Amount"
        />
      </div>
      <div
        onClick={investFunc}
        ref={investBtn}
        id="disabled"
        className="invest-card-btn"
      >
        <div>Invest</div>
      </div>
    </div>
  );
}
