import { useState } from "react";
import {resetPassword} from ".././../services/api"
import {validateEmail} from "../../utilities"
import "./auth.scss";
export default function Forgot() {
  const [email, setEmail] = useState("")
  const [errMsg, setErrMsg] = useState("");

  const reset = async() => {
    if (!validateEmail(email)){
      setErrMsg("Enter a valid email address")
    }
    try {
      const resp = await resetPassword({email})
      if(resp){
        setErrMsg("Password Reset Information sent!")
      }
    } catch (error) {
      setErrMsg(error.response.data.message)
    }
  }
  return (
    <div className="body">
      <div className="auth_container">
        <div className="head">Newdaawn</div>
        <div className="formContainer">
          <div className="formHead">Reset Password</div>
          <div className="form">
          <div class="err">{errMsg}</div>
            <label id="formItem" className="email">
              <div>Enter Email Address To Reset Password</div>
              <div className="input">
                <span className="material-symbols-outlined">mail</span>
                <input
                  type="email"
                  required
                  name="mail"
                  id="mail"
                  onChange={(e) => { setEmail(e.target.value) }}
                />
              </div>
              <div className="err"></div>
            </label>

            <div className="button hasLoad recover">
              <span className="loader"></span>
              <div onClick={reset}>Reset Password</div>
            </div>
            <div className="alt">
              Don't have an account?
              <a href="/signup">
                <span>Sign Up</span>
              </a>
            </div>
            <div className="copyright">
              &copy; Copyright 2023 All Right Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
