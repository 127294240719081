import "./referal.scss";
import { useEffect, useState } from "react";
import Menubar from "../../../components/menubar";
import Head from "../../../components/menubar/head";
import { Overview } from "../../../components/overview";
import TradingViewWidget from "../../../widgets/Crypto";
import Account from "../../../components/account";
import { RecentTransactions } from "../../../components/RecentTransaction";
import DocumentTitle from "react-document-title";
import Message from "../../../components/tools/Message";
import { dashboard, logOut } from "../../../services/api";
import { copyText } from "../../../utilities";
export function Referal({
  invite = 0,
  accepted = 0,
}) {

  useEffect(() => {
    dashboard_load()
  
  }, [])
  

const dashboard_load = async () => {
  try {
    let resp = await dashboard();
    if (resp) {
      setUrlLink(`${window.location.host}/signup?ref=${resp.data?.id}`);
    }
  } catch (error) {
    logOut();
    console.log(error);
  }
};

  sessionStorage.setItem("mainmenu", 4);
  if (sessionStorage.getItem("systemmenu")) {
    sessionStorage.removeItem("systemmenu");
  }
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [ShowMenu, setShowMenu] = useState(false);
  const [urlLink, setUrlLink] = useState("");
  window.addEventListener("resize", () => {
    setBrowserWidth(window.innerWidth);
  });
  const showmenu = (e) => {
    setShowMenu(e);
    console.log(e);
  };
  const [link, setLink] = useState("");
  const [icon, setIcon] = useState("fa-copy fa-bounce");

  const copyLink = (e) => {
    setLink("Link Copied Successfully");
    setIcon("fa-circle-check");
    copyText(urlLink)
    var x = setTimeout(() => {
      setLink(urlLink);
      setIcon("fa-copy fa-bounce");
    }, 3000);
  };
  return (
    <DocumentTitle title="Newdaawn | Referrals">
      <div className="referal">
        <Head showMenu={showmenu} />
        {browserWidth > 0 && <Menubar showMenu={ShowMenu} />}
        <div className="referal-container">
          <div className="referal-image"></div>
          <div className="referal-main">
            <div className="referal-head">
              Refer friends and you'll both get $20
            </div>
            <div className="referal-desc">
              Each time a friend signs up through your referral link and fund
              account we'll reward you both $20
            </div>
            <div className="referal-link" onClick={copyLink}>
              <div>{urlLink}</div>
              <span className={`fa-regular ${icon}`}></span>
            </div>
            <div className="referal-body">
              <div className="referal-body-params">
                <div>Invites Sent</div>{" "}
                <div>{(invite && invite) || 0} invites</div>
              </div>
              <div className="referal-body-params">
                <div>Accepted Invites</div>{" "}
                <div>{(accepted && accepted) || 0} invites</div>
              </div>
              <div className="referal-body-params">
                <div>Earnings </div>{" "}
                <div>${(accepted && accepted * 20) || 0}</div>
              </div>
            </div>
            <div className="referal-body-btn">Invite Now</div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}
