import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import DocumentTitle from "react-document-title";
export default function Index() {
  const [menuState, setMenuState] = useState(false);
  const navigate = useNavigate();
  // useEffect(() => {
  //   DocumentTitle.setTitle("My Page Title");
  // }, []);

  const navigatePath = (path) => {
    navigate(path);
  };

  window.addEventListener("scroll", () => {
    if (document.querySelectorAll(".header")[0]) {
      if (window.scrollY > 20) {
        document.querySelectorAll(".header")[0].id = "sticky";
      } else {
        document.querySelectorAll(".header")[0].id = "";
      }
    }
  });

  const cDate = document.querySelectorAll(".cDate")[0];
  if (cDate) {
    cDate.textContent = new Date().getFullYear();
  }

  const menubar = useRef(null);
  const slide = (e) => {
    var menu = document.querySelectorAll(".xMenu")[0];
    if (!menuState) {
      menu.id = "slide";
      e.target.textContent = "close";
      setMenuState(!menuState);
    } else {
      menu.id = "";
      setMenuState(!menuState);
      e.target.textContent = "menu";
    }
  };

  const home = (e) => {
    setMenuState(!menuState);
    for (let i = 0; i < 4; i++) {
      e.target.parentElement.children[i].id = "menuItems";
    }
    e.target.id = "activeMenu";
    menubar.current.click();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const services = (e) => {
    const tabs = document.querySelectorAll("#tabs");
    setMenuState(!menuState);
    for (let i = 0; i < 4; i++) {
      e.target.parentElement.children[i].id = "menuItems";
    }
    e.target.id = "activeMenu";
    menubar.current.click();
    tabs[0].scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const invest = (e) => {
    const tabs = document.querySelectorAll("#tabs");
    setMenuState(!menuState);
    for (let i = 0; i < 4; i++) {
      e.target.parentElement.children[i].id = "menuItems";
    }
    e.target.id = "activeMenu";
    menubar.current.click();
    tabs[1].scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <DocumentTitle title="Newdaawn">
      <>
        <img
          src={process.env.PUBLIC_URL + "/images/atom.jpg"}
          alt="atom"
          className="fixedBg"
        />
        <div className="header">
          <div id="logo">
            <img
              src={process.env.PUBLIC_URL + "/assets/stamp.png"}
              alt="Newdaawn Logo"
            />
          </div>
          <div className="menu">
            <div className="homeLink" id="menuItems" onClick={home}>
              Home
            </div>
            <div className="servicesLink" id="menuItems" onClick={services}>
              Services
            </div>
            <div className="investLink" id="menuItems" onClick={invest}>
              Investment
            </div>
            <div className="" id="menuItems">
              Contacts
            </div>
          </div>
          <div className="actions">
            <div
              onClick={() => {
                navigatePath("/login");
              }}
              id="login"
            >
              Login
            </div>
            <div
              onClick={() => {
                navigatePath("/signup");
              }}
              id="started"
            >
              Get Started
            </div>
          </div>
          <div className="menubar">
            <span
              onClick={slide}
              ref={menubar}
              className="material-symbols-outlined"
            >
              menu
            </span>
          </div>
        </div>
        <div className="xMenu">
          <div className="lists">
            <li className="homeLink" id="menuItems" onClick={home}>
              Home
            </li>
            <li className="servicesLink" id="menuItems" onClick={services}>
              Services
            </li>
            <li className="investLink" id="menuItems" onClick={invest}>
              Investment
            </li>
            <li id="menuItems">Contacts</li>
          </div>
          <div className="xActions">
            <div onClick={() => { navigatePath("/login"); }} id="login">Login</div>
            <div id="started" onClick={() => { navigatePath("/signup"); }} >Get Started</div>
          </div>
        </div>
        <div className="container">
          <div className="trade">
            <div className="trade_content">
              <div className="trade_title">Trade Invest Stock, and Bond</div>
              <div className="trade_head">The Better Way to Trade & Invest</div>
              <div className="trade_desc">
                We help over 2 million customers achieve their financial goals
                by helping them trade and invest with ease
              </div>
              <div onClick={() => {
                    navigatePath("/signup");
                  }} className="trade_btn">
                <span
                  
                >
                  Create Account
                </span>
              </div>
            </div>
            <div className="trade_image">
              <img src={"./images/trade-bg.jpg"} width={"100%"} alt="trade" />
            </div>
          </div>
          <div className="marquee">
            <iframe
              src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=light&pref_coin_id=1505&invert_hover="
              frameBorder="0"
              title="."
            ></iframe>
          </div>
          <div className="safety">
            <div className="safetyHead">Safety is the top priority</div>
            <div className="safetyContainer">
              <div className="safetyCards">
                <div className="cardIcon">
                  <span className="fas fa-shield-alt"></span>
                </div>
                <div className="cardTitle">Protection</div>
                <div className="cardDesc">
                  Our system is locked down and secure with 256-bit SSL
                  encryption to protect your information from fraud.
                </div>
              </div>
              <div className="safetyCards">
                <div className="cardIcon">
                  <span className="fas fa-user-lock"></span>
                </div>
                <div className="cardTitle">Two Step Verification</div>
                <div className="cardDesc">
                  Two-factor authentication (2FA) enabled by default for all
                  accounts, ensuring enhanced security against unauthorized
                  access and impersonation.
                </div>
              </div>
            </div>
          </div>
          <div id="tabs" className="explore">
            <div className="exploreHead">Discover Our Services</div>
            <div className="exploreTitle">
              Our mission is to offer you an enjoyable and prosperous trading
              journey!
            </div>
            <div className="exploreContainer">
              <div className="exploreCardContainer">
                <div className="exploreCards">
                  <div className="cardIcon">
                    <span className="material-symbols-outlined">
                      monitoring
                    </span>
                  </div>
                  <div className="cardTitle">Robust Trading Platforms</div>
                  <div className="cardDesc">
                    We provide a range of platform choices to cater to the
                    diverse needs of traders and investors.
                  </div>
                </div>
                <div className="exploreCards">
                  <div className="cardIcon">
                    <span className="material-symbols-outlined">bolt</span>
                  </div>
                  <div className="cardTitle">Swift Execution</div>
                  <div className="cardDesc">
                    Utilize lightning-fast trading software to eliminate the
                    risk of slippage and ensure seamless trading.
                  </div>
                </div>
                <div className="exploreCards">
                  <div className="cardIcon">
                    <span className="material-symbols-outlined">
                      credit_card
                    </span>
                  </div>
                  <div className="cardTitle">Amplified Leverage</div>
                  <div className="cardDesc">
                    An opportunity to multiply investments for significant gains
                    with ultra-low spreads boosting profits.{" "}
                  </div>
                </div>
                <div className="exploreCards">
                  <div className="cardIcon">
                    <span className="material-symbols-outlined">vpn_key</span>
                  </div>
                  <div className="cardTitle">Ultimate Security</div>
                  <div className="cardDesc">
                    Our state-of-the-art security systems are dedicated to
                    keeping your account perpetually safeguarded.
                  </div>
                </div>
                <div className="exploreCards">
                  <div className="cardIcon">
                    <span className="material-symbols-outlined">
                      Headset_Mic
                    </span>
                  </div>
                  <div className="cardTitle">24/7 Live Chat Support</div>
                  <div className="cardDesc">
                    Access our 24/7 support and on-demand market analysts
                    whenever you need them.
                  </div>
                </div>
              </div>
              <div className="exploreImage">
                <img src="./images/services_img.png" width="100%" alt="" />
              </div>
            </div>
          </div>
          <div className="invest" id="tabs">
            <div className="investHead">Investment Strategies</div>
            <div className="investContainer">
              <div className="investImage">
                <img src="./images/laptop_img.png" width="100%" alt="" />
              </div>
              <div className="investCardContainer">
                <div className="investCards">
                  <div className="cardIcon">
                    <span className="material-symbols-outlined">money</span>
                  </div>
                  <div className="cardTitle">Value Investing</div>
                  <div className="cardDesc">
                    We provide In-depth coverage of value investing principles,
                    case studies, and best practices.
                  </div>
                </div>
                <div className="investCards">
                  <div className="cardIcon">
                    <span className="material-symbols-outlined">
                      trending_up
                    </span>
                  </div>
                  <div className="cardTitle">Growth Investing</div>
                  <div className="cardDesc">
                    Insights into growth stock investments, identifying growth
                    opportunities, and risk management.
                  </div>
                </div>
                <div className="investCards">
                  <div className="cardIcon">
                    <span className="material-symbols-outlined">savings</span>
                  </div>
                  <div className="cardTitle">Income Strategies</div>
                  <div className="cardDesc">
                    Strategies for generating passive income through dividend
                    stocks, bonds, and real estate.
                  </div>
                </div>
                <div className="investCards">
                  <div className="cardIcon">
                    <span className="material-symbols-outlined">analytics</span>
                  </div>
                  <div className="cardTitle">Sector Analysis</div>
                  <div className="cardDesc">
                    {" "}
                    Our focus on analyzing specific industries and sectors for
                    investment opportunities.
                  </div>
                </div>
                <div className="investCards">
                  <div className="cardIcon">
                    <span className="material-symbols-outlined">finance</span>
                  </div>
                  <div className="cardTitle">International Investing</div>
                  <div className="cardDesc">
                    We Guide you on investing in international markets,
                    including risks and benefits.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accountCardContainer">
            <div className="accountCard">
              <div className="accountCardTitle">
                Making Trading and Investing Easier
              </div>
              <div className="accountCardDesc">
                We Supports Over 2 Million Customers in Reaching Their Money
                Goals. by helping them trade and invest with ease
              </div>
              <div
                onClick={() => {
                  navigatePath("/signup");
                }}
                className="accountBtn"
              >
                Create Free Account
              </div>
            </div>
          </div>
          <div className="footer">
            &copy; Newdaawn <span className="cDate"></span>
          </div>
        </div>
      </>
    </DocumentTitle>
  );
}
