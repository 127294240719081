import {} from "../constants"

export function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  
  export function copyText(text) {
    try {
      navigator.clipboard.writeText(text);
      
    } catch (error) {
      // alert(error)
    }
  }

  export function passwordMatch(a, b) {
    return a === b ? true : false;
  }

  export function stringLength(string, len) {
    return string.length >= len ? true : false;
  }

  export function validFullName(name) {
    return name?.split(' ').length > 1 ? true : false
  }
  

  export function notAuthenticated() {
    window.location.assign("/login");
  }
  
  export function authenticatedRoute() {
    window.location.assign("/dashboard");
  }
  export function customRedirect(path) {
    window.location.assign(path);
  }
  
  export function saveUserCache(user) {
      sessionStorage.setItem('_cache_', JSON.stringify(user))
  }
  
  export function setAccessToken(token){
    sessionStorage.setItem('__token__', token)
  }

  export function fetchUserCache(){
      return JSON.parse(sessionStorage.getItem('_cache_'))
  }
  
  // export async function syncDashboard(setUser, setLoading, ) {
  //     let __user = fetchUserCache()
  //     if (__user) {
  //       setUser(__user)
  //       setLoading(false)
  //     } else {
  //       try {
  //         let resp = await dashboard();
  //         if (resp.status) {
  //           setUser(resp.data);
  //           saveUserCache(resp.data)
  //           setLoading(false);
  //         }
  //       } catch (error) {
  //         notAuthenticated();
  //       }
  //     }
  //   };