import "./overview.scss";
import { Link } from "react-router-dom";
export function Overview({ user }) {
  return (
    <>
      <div className="intro">
        <div className="intro-head">Good Morning</div>
        <div className="intro-desc">
          Monitor how your Investment are working
        </div>
      </div>
      <div className="balance">
        <OverviewCard
          icon={"account_balance_wallet"}
          title={"Account Balance"}
          amount={"$" + parseFloat(user?.balance).toLocaleString()}
          btn={"Fund"}
          btn_name={"add_circle"}
          link={"/deposit"}
        />
      </div>
      <div className="profit">
        <OverviewCard
          icon={"attach_money"}
          title={"Total Profit"}
          amount={"$" + parseFloat(user?.profit).toLocaleString()}
          btn={"Invest"}
          btn_name={"savings"}
          link={"/withdraw"}
        />
      </div>
    </>
  );
}
export function OverviewCard({ icon, title, amount, btn, btn_name, link }) {
  return (
    <>
      <div className="overview-card">
        <div className="overview-card-head">
          <div className="overview-card-icon">
            <span className="material-symbols-outlined">{icon}</span>
          </div>
          <div className="overview-card-title">{title}</div>
        </div>
        <div className="overview-card-body">
          <span>{amount === `$${NaN}` ? "" : amount}</span>
          <Link to={link} title={btn} className="overview-card-body-btn">
            <span className="material-symbols-outlined">{btn_name}</span>
          </Link>
        </div>
      </div>
    </>
  );
}
