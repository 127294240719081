import { CustomTable } from "../customTable";
import { Link } from "react-router-dom";
import "./recent.scss";
import {useEffect, useState} from "react"
import {recent_transactions} from "../../services/api"

export function RecentTransactions() {

  const [transactionList, setTransactionList] = useState([])

  useEffect(() => {
    load_data()

  }, [])
  

  const load_data = async () => {
    let data = await recent_transactions()
    setTransactionList(data)
  }
  return (
    <div className="recent-transactions-container">
      <div className="recent-transactions-head">
        <div>Recent Transactions</div>
        <Link to="/transactions">View All</Link>
      </div>
      <div className="recent-transactions-table">
        <CustomTable
          type="head"
          param={["Type", "Date", "Amount", "Status"]}
          tableLength={transactionList.length}
          tableName={"Recent Transactions History"}
        />
        {transactionList.map((data, index) => (
          <CustomTable key={index} type="body" param={data} />
        ))}
      </div>
    </div>
  );
}
