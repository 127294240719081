import { useEffect, useRef, useState } from "react";
import "./profit.scss";
import Head from "../../../components/menubar/head";
import Menubar from "../../../components/menubar";
import { CustomTable } from "../../../components/customTable";
import { investments_data } from "../../../services/api";

export default function Profit() {
  const [investments, setInvestments] = useState([])

  useEffect(() => {
    load_profits()
  }, [])
  


  const load_profits = async () => {
    const data = await investments_data()
    setInvestments(data)
  }

  const AllTransactionList = [
    ["", "Gold", "$320.00", "$100", "$100","Nov 15"],
    ["", "Bronze", "$500.00", "$100", "$100","Nov 2"],
    ["", "Bronze", "$120.00", "$100", "$100","Oct 28"],
    ["", "Platinum", "$250.00", "$100", "$100","Oct 21"],
  ];

  sessionStorage.setItem("mainmenu", 1);
  if (sessionStorage.getItem("systemmenu")) {
    sessionStorage.removeItem("systemmenu");
  }
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [ShowMenu, setShowMenu] = useState(false);
  window.addEventListener("resize", () => {
    setBrowserWidth(window.innerWidth);
  });
  const showmenu = (e) => {
    setShowMenu(e);
  };
  const [tableindex, setTableindex] = useState(0);
  const table_nav = useRef(null);
  useEffect(() => {
    if (table_nav.current) {
      for (let j = 0; j < 3; j++) {
        table_nav.current.children[j].id = "";
      }
      table_nav.current.children[tableindex].id = "active_nav";
      if (tableindex > 0) {
        table_nav.current.parentElement.lastChild.id = "table_rad";
      } else {
        table_nav.current.parentElement.lastChild.id = "";
      }

      for (let i = 0; i < 3; i++) {
        table_nav.current.children[i].addEventListener("click", () => {
          setTableindex(i);
        });
      }
    }
  }, [tableindex]);

  return (
    <div className="profits">
      <Head showMenu={showmenu} />
      {browserWidth > 0 && <Menubar showMenu={ShowMenu} />}
      <div className="profits-container">
        <div className="profits-head">Your Investments</div>
        <div className="profits-body">
          <div className="profits-table">
            <div className="profits-table-container">
              <CustomTable
                type="head"
                param={["Plan", "Capital", "Weekly", "Total","Date"]}
                tableLength={investments.length}
                tableName={"Transaction History"}
              />
              {investments.map((data, index) => (
                <CustomTable key={index} type="body" param={data} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
