import { useState, useEffect } from "react";
import Menubar from "../../../components/menubar";
import Head from "../../../components/menubar/head";
import "./dashboard.scss";
import { Overview } from "../../../components/overview";
import TradingViewWidget from "../../../widgets/Crypto";
import Account from "../../../components/account";
import { RecentTransactions } from "../../../components/RecentTransaction";
import DocumentTitle from "react-document-title";
import { dashboard, logOut } from "../../../services/api";

export default function Dashboard() {
  useEffect(() => {
    dashboard_load();
  }, []);

  sessionStorage.setItem("mainmenu", 0);
  if (sessionStorage.getItem("systemmenu")) {
    sessionStorage.removeItem("systemmenu");
  }
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [ShowMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState(false);

  window.addEventListener("resize", () => {
    setBrowserWidth(window.innerWidth);
  });
  const showmenu = (e) => {
    setShowMenu(e);
    // console.log(e);
  };

  const dashboard_load = async () => {
    try {
      let resp = await dashboard();
      if (resp) {
        setUser(resp.data);
      }
    } catch (error) {
      logOut();
      console.log(error);
    }
  };

  return (
    <DocumentTitle title="Newdaawn | Dashboard">
      <div className="dashboard">
        <Head showMenu={showmenu} />
        {browserWidth > 0 && <Menubar showMenu={ShowMenu} />}
        <div className="dashboard-container">
          <div className="dashboard-overview">
            <Overview user={user} />
          </div>
          <div className="dashboard-widget">
            <div className="dashboard-widget-title">Currency Price Graph</div>
            <TradingViewWidget />
          </div>
          <div className="dashboard-account">
            <Account user={user} />
          </div>
          <div className="dashboard-recent-transaction">
            <RecentTransactions />
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}
