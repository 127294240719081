import axios from "axios";
import { API_URL, TOKEN, AUTH_HEADER } from "../constants";

export async function registerUser(payload) {
  let resp = await axios.post(`${API_URL}/register`, payload);
  return resp;
}

export async function loginUser(payload) {
  let resp = await axios.post(`${API_URL}/login`, payload);
  return resp;
}

export async function resetPassword(payload) {
  let resp = await axios.post(`${API_URL}/forgot-password`, payload);
  return resp;
}

export function logOut() {
  sessionStorage.clear();
  window.location.assign("/login");
}

export async function dashboard() {
  let resp = await axios.get(`${API_URL}/user/profile`, {
    headers: AUTH_HEADER,
  });
  return resp;
}

export async function transactions() {
  let resp = await axios.get(`${API_URL}/user/transactions`, {
    headers: AUTH_HEADER,
  });
  return resp;
}

export async function profits() {
  let resp = await axios.get(`${API_URL}/user/transactions`, {
    headers: AUTH_HEADER,
  });
  return resp;
}

export async function investments() {
  let resp = await axios.get(`${API_URL}/user/investments`, {
    headers: AUTH_HEADER,
  });
  return resp;
}

export async function investments_data(){
  try {
    const resp = await investments()
    if(resp) {
      let data_build = []
      resp.data?.data.forEach((current)=>{
        let obj = ["", current?.plan, "$"+current?.amount.toLocaleString(), "$"+current?.weekly_profit.toLocaleString(), "$"+current?.accumulated_profit.toLocaleString(), current?.date]
        data_build.push(obj)
      })
      return data_build
    }
  } catch (error) {
    // console.log(error)
    // return []
    logOut()
  }
}

export async function recent_transactions() {
  try {
    const resp = await transactions();
    if (resp) {
      let trans = [...resp.data?.deposits, ...resp.data?.withdraws];
      let data_build = [];

      for (let i = 0; i < 5; i++) {
        let current = trans[i];
        if (!current?.type) {
          break;
        }
        let obj = [
          current?.status ? "00D400" : "#F60000",
          current?.type,
          current?.date,
          `$${current?.amount.toLocaleString()}`,
          current?.status ? "Successful" : "Failed",
        ];
        data_build.push(obj);
      }
      return data_build;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function transaction_history(){
  try {
    const resp = await transactions();
    if (resp) {
      // deposit
      let deposits = []
      resp.data?.deposits.forEach(current => {
        let obj = [
          current?.status ? "#00D400" : "#F60000",
          current?.type,
          current?.date,
          `$${current?.amount.toLocaleString()}`,
          current?.status ? "Approved" : "Failed",
        ];
        deposits.push(obj)
      });
      
      // withdraws
      let withdraws = []
      resp.data?.withdraws.forEach(current => {
        let obj = [
          current?.status ? "#00D400" : "#F60000",
          current?.type,
          current?.date,
          `$${current?.amount.toLocaleString()}`,
          current?.status ? "Approved" : "Failed",
        ];
        withdraws.push(obj)
      });

      return {deposits, withdraws}
    }
  } catch (error) {
      console.log(error)
  }
}


export async function invest_api(amount, plan) {
  const payload = {amount, plan}
  const resp = await axios.post(`${API_URL}/user/invest`, payload, {
    headers: AUTH_HEADER,
  });
  return resp;
}

export async function deposit(amount, method, hash) {
  const payload = {method, amount, hash}
  const resp = await axios.post(`${API_URL}/new-deposit`, payload, {
    headers: AUTH_HEADER,
  });
  return resp;
}

export async function update_password(old_password, new_password) {
  const payload = {old_password, new_password}
  const resp = await axios.post(`${API_URL}/user/update-password`, payload, {
    headers: AUTH_HEADER,
  });
  return resp;
}