import './App.scss';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './pages/auth/login';
import Index from './pages/index';
import Signup from './pages/auth/signup';
import Forgot from './pages/auth/forgot';
import Dashboard from './pages/layout/dashboard/dashboard';
import Transactions from './pages/layout/transactions/transactions';
import Profit from './pages/layout/profit/profit';
import Error from './pages/error';
import { Deposit } from './pages/layout/deposit/deposit';
import { useEffect } from 'react';
import { Invest } from './pages/layout/invest/invest';
import { Withdraw } from './pages/layout/withdraw/withdraw';
import { Referal } from './pages/layout/referals/referal';
import Settings from './pages/layout/settings/Settings';
function App() {

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      var div = document.querySelectorAll('div');
      var span = document.querySelectorAll('span');
      for (var i = 0; i < div.length; i++) {
        div[i].style.cursor = 'text';
      }
      for (var j = 0; j < span.length; j++) {
        span[j].style.cursor = 'text';
      }
    }
  }, [])



  const links = createBrowserRouter([
    {
      path: "/",
      element: <><Index />  </>,
      errorElement: <Error />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/reset",
      element: <Forgot />,
    },
    {
      path: "/dashboard",
      element: <><Dashboard /> </>,
    },
    {
      path: "/transactions",
      element: <><Transactions /> </>,
    },
    {
      path: "/profit",
      element: <><Profit /> </>,
    },
    {
      path: "/deposit",
      element: <><Deposit /> </>,
    },
    {
      path: "/invest",
      element: <><Invest /> </>,
    },
    {
      path: "/withdraw",
      element: <><Withdraw /> </>,
    },
    {
      path: "/refer",
      element: <><Referal /> </>,
    },
    {
      path: "/settings",
      element: <><Settings /> </>,
    }
  ]);

  return (
    <RouterProvider router={links} />
  );
}
export default App;
