import "./error.scss";
import { Link, useRouteError } from "react-router-dom";
export default function Error() {
  const style = {
    width: "80%",
    maxWidth: "500px",
  };
  return (
    <div className="error-page">
      <div className="oops">Oops!</div>
      <div id="err_img">
        <img
          style={style}
          src={process.env.PUBLIC_URL + "/404.png"}
          alt="404"
        />
      </div>
      <Link className="link" to="/login">
        Return to login page
      </Link>
    </div>
  );
}
