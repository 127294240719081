import { useRef, useState } from "react";
import DocumentTitle from "react-document-title";

import {
  validateEmail,
  stringLength,
  setAccessToken,
  authenticatedRoute,
} from "../../utilities";
import { loginUser } from "../../services/api";
import { useNavigate } from "react-router-dom";
import "./auth.scss";
import { AuthHead } from "./head";

export default function Login() {
  const [viewPwd, setViewPwd] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [errMsg, setErrMsg] = useState("");
  const [mail, setMail] = useState("");
  const mailRef = useRef(null);
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const formDataKeyUp = (key, value) => {
    setLoginData({ ...loginData, [key]: value });
  };

  const pwdInput = (e) => {
    if (loginData?.password.length > 0) {
      setViewPwd(true);
    } else {
      setViewPwd(false);
    }
  };

  const toggle = () => {
    setInputType(inputType === "text" ? "password" : "text");
  };

  const login = async () => {
    try {
      if (!validateEmail(loginData?.email)) {
        setErrMsg("Invalid Email Address");
      } else if (!stringLength(loginData?.password, 6)) {
        setErrMsg("password must be minimum of 6 characters");
      } else {
        let resp = await loginUser(loginData);
        if (resp.status) {
          setErrMsg("User Authenticated");
          setAccessToken(resp.data.access_token);
          authenticatedRoute();
        }
      }
    } catch (error) {
      setErrMsg(error.response.data.message);
    }
  };

  return (
    <DocumentTitle title="Newdaawn | Signup">
      <div className="body">
        <div class="auth_container">
          <AuthHead />
          <div class="formContainer">
            <div class="formHead">Login To Newdaawn</div>
            <div class="form">
              <label id="formItem" class="email">
                <div>Email</div>
                <div class="input">
                  <span class="material-symbols-outlined">mail</span>
                  <input
                    type="email"
                    required
                    ref={mailRef}
                    name="mail"
                    id="mail"
                    onKeyUp={(e) => {
                      formDataKeyUp("email", e.target.value);
                    }}
                  />
                </div>
              </label>
              <label id="formItem" class="password">
                <div>Password</div>
                <div class="input">
                  <span class="material-symbols-outlined">lock</span>
                  <input
                    type={inputType}
                    name="password"
                    required
                    minlength="5"
                    id="pwd"
                    onChange={pwdInput}
                    onKeyUp={(e) => {
                      formDataKeyUp("password", e.target.value);
                    }}
                  />
                  <div class="toggle">
                    {viewPwd && (
                      <span onClick={toggle} class="material-symbols-outlined">
                        visibility_off
                      </span>
                    )}
                  </div>
                </div>
              </label>
              <div class="err">{errMsg}</div>
              <label class="options">
                <label htmlFor="check" class="remember">
                  <input type="checkbox" name="remember" id="check" />
                  <span class="material-symbols-outlined"></span>
                  <span>Remember me</span>
                </label>
                <a href="/reset">
                  <div class="forgot">Forgotten Password?</div>
                </a>
              </label>
              <div class="button hasLoad" onClick={login}>
                <span class="loader"></span>
                <div class="login">Login</div>
              </div>
              <div class="alt">
                Don't have an account?{" "}
                <a href="/signup">
                  <span>Sign Up</span>{" "}
                </a>{" "}
              </div>
              <div class="copyright">
                &copy; Copyright 2023 All Right Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}
