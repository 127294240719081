import { useEffect, useRef, useState } from "react";
import "./menubar.scss";
import { Link } from "react-router-dom";

export default function Menubar({ showMenu }) {
  const menuContainer = useRef(null);
  const mainMenu = useRef(null);
  const systemMenu = useRef(null);
  const [menuItems, setMenuItems] = useState(["", "", "", "", ""]);
  const [systemItems, setSystemItems] = useState(["", ""]);
  const [linkName, setLinkName] = useState([
    "dashboard",
    "profit",
    "transactions",
    "invest",
    "refer",
  ]);

  const [systemLink, setSystemLink] = useState(["settings", "login"]);

  useEffect(() => {
    if (showMenu) {
      spread();
    } else {
      unSpread();
    }
  }, [showMenu]);
  useEffect(() => {
    if (mainMenu.current && sessionStorage.getItem("mainmenu")) {
      mainMenu.current.children[
        sessionStorage.getItem("mainmenu")
      ].firstChild.classList = "mainMenuItemsIcon activeMenu";
      mainMenu.current.children[sessionStorage.getItem("mainmenu")].classList =
        "mainMenuItems activeMenu";
    }
    if (systemMenu.current && sessionStorage.getItem("systemmenu")) {
      systemMenu.current.children[
        sessionStorage.getItem("systemmenu")
      ].firstChild.classList = "mainMenuItemsIcon activeMenu";
      systemMenu.current.children[
        sessionStorage.getItem("systemmenu")
      ].classList = "mainMenuItems activeMenu";
    }
  }, [menuItems]);

  const [menuIcons, setMenuIcons] = useState([
    "home_app_logo",
    "account_balance",
    "receipt_long",
    "savings",
    "campaign",
  ]);
  const [systemIcons, setSystemIcons] = useState(["settings", "logout"]);

  const spread = () => {
    menuContainer.current.id = "spread";
    menuContainer.current.parentElement.id = "spreadParent";
    // setTimeout(() => {
    setMenuItems([
      "Dashboard",
      "Investments",
      "Transaction History",
      "Invest",
      "Refer and Earn",
    ]);
    setSystemItems(["Settings", "Logout"]);
  };
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setBrowserWidth(window.innerWidth);
    });
    if (browserWidth >= 1240) {
      spread();
    } else {
      unSpread();
    }
  }, [browserWidth]);

  const unSpread = () => {
    if (browserWidth < 1240 && !showMenu) {
      menuContainer.current.id = "";
      menuContainer.current.parentElement.id = "";
      setMenuItems(["", "", "", "", ""]);
      setSystemItems(["", ""]);
    }
  };

  return (
    <div className="dashboardMenuContainer">
      <div
        ref={menuContainer}
        className="dashboardMenu"
        onMouseEnter={spread}
        onMouseLeave={unSpread}
      >
        <div className="mainMenu" ref={mainMenu}>
          {menuItems.map((name, index) => (
            <Link
              to={`/${linkName[index]}`}
              className="mainMenuItems"
              key={name + index.toString()}
            >
              <div className="mainMenuItemsIcon">
                <span className="material-symbols-outlined">
                  {menuIcons[index]}
                </span>
              </div>
              <div className="mainMenuItemsText">{name}</div>
            </Link>
          ))}
        </div>
        <div className="systemMenu" ref={systemMenu}>
          {systemItems.map((name, index) => (
            <Link
              className="mainMenuItems"
              key={name + index}
              to={`/${systemLink[index]}`}
            >
              <div className="mainMenuItemsIcon">
                <span className="material-symbols-outlined">
                  {systemIcons[index]}
                </span>
              </div>
              <div className="mainMenuItemsText">{name}</div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
