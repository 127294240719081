import { useEffect, useRef, useState } from "react";
import "./table.scss";
export function CustomTable({ type, param, tableLength, tableName }) {
  const [paramData, setParamData] = useState([]);
  const arrange = {
    gridTemplateColumns: `repeat(${param.length}, 1fr)`,
  };
  const arrangeBody = {
    gridTemplateColumns: `repeat(${param.length - 1}, 1fr)`,
  };

  const tableBody = useRef(null);
  useEffect(() => {
    const status = tableBody.current;
    if (status) {
      status.children[param.length - 1].style.color = [param[0]];
    }
    setParamData(param);
  }, [param]);

  if (type === "head") {
    return (
      <>
        {tableLength < 1 ? (
          <>
            {" "}
            <div className="custom-table-empty">
              <span className="material-symbols-outlined">hourglass</span>
              <span>Sorry No {tableName}</span>
            </div>
          </>
        ) : (
          <>
            <div className="custom-table-head" style={arrange}>
              {paramData.map((data, index) => (
                <div key={index} className="custom-table-head-data">
                  {data}
                </div>
              ))}
            </div>
          </>
        )}
      </>
    );
  } else {
    return (
      <div ref={tableBody} className="custom-table-body" style={arrangeBody}>
        {param.map((data, index) => (
          <div key={index} className="custom-table-data">
            {data}
          </div>
        ))}
      </div>
    );
  }
}
