import { Link } from "react-router-dom";
import { logOut } from "../../services/api";

import "./account.scss";
export default function Account({ user }) {
  return (
    <div className="dashboard-account-container">
      <div className="dashboard-account-head">
        <Link
          to={"/settings"}
          className="dashboard-account-edit"
          title="Edit Account Information"
        >
          <span className="material-symbols-outlined">edit_square</span>
          <span>Edit Account Info</span>
        </Link>
        <Link
          onClick={logOut}
          className="dashboard-account-logout"
          title="Logout"
        >
          <span>Logout</span>
          <span className="material-symbols-outlined">logout</span>
        </Link>
      </div>
      <div className="dashboard-account-body">
        <div className="dashboard-account-photo">
          <img src={user?.image} alt="profile" />
        </div>
        <div className="dashboard-account-name">{user?.fullname}</div>
        <div className="dashboard-account-mail">{user?.email}</div>
      </div>
      <div className="dashboard-account-last">
        <div className="dashboard-account-deposit">
          <Link to={"/deposit"}>Deposit</Link>
        </div>
        <div className="dashboard-account-withdraw">
          <Link to={"/withdraw"}>Withdraw</Link>
        </div>
      </div>
    </div>
  );
}
