import { useState, useEffect } from "react";
import Menubar from "../../../components/menubar";
import Head from "../../../components/menubar/head";
import "./settings.scss";
import DocumentTitle from "react-document-title";
import { WithdrawCard } from "../withdraw/withdraw";
import { update_password, dashboard, logOut } from "../../../services/api";

export default function Settings() {
  sessionStorage.setItem("systemmenu", 0);
  if (sessionStorage.getItem("mainmenu")) {
    sessionStorage.removeItem("mainmenu");
  }
  const [err, setErr] = useState(false);
  const [pwdErr, setPwdErr] = useState(false);
  const [pwdv, setPwdv] = useState("");
  const [confirmPwdv, setConfirmPwdv] = useState("");
  const [errState, setErrState] = useState(false);
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [ShowMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState({});
  window.addEventListener("resize", () => {
    setBrowserWidth(window.innerWidth);
  });

  useEffect(() => {
    dashboard_load();
  }, []);
  

  const dashboard_load = async () => {
    try {
      let resp = await dashboard();
      if (resp) {
        setUser(resp.data);
      }
    } catch (error) {
      logOut();
      console.log(error);
    }
  };

  const showmenu = (e) => {
    setShowMenu(e);
    console.log(e);
  };

  function isValidEmail(email) {
    const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return pattern.test(email);
  }

  const fullname = (e) => {
    if (e.value.length < 5) {
      e.style.borderColor = "red";
      setErrState("Invalid Fullname");
    } else {
      e.style.borderColor = "green";
      setErrState(false);
    }
  };
  const mail = (e) => {
    if (!isValidEmail(e.value)) {
      e.style.borderColor = "red";
      setErrState("Invalid Mail");
    } else {
      e.style.borderColor = "green";
      setErrState(false);
    }
  };
  const ssn = (e) => {
    if (e.value.length >= 9 && e.value.length <= 11) {
      e.style.borderColor = "green";
      setErrState(false);
    } else {
      e.style.borderColor = "red";
      setErrState("Invalid SSN");
    }
  };

  const submit = () => {
    if (errState) {
      setErr([errState, "red"]);
    } else {
      setErr(["Succesfully Updated", "green"]);
    }
  };

  const pwd = (e) => {
    setPwdv(e.value);
  };
  const confirmPwd = (e) => {
    setConfirmPwdv(e.value);
  };

  const updatePassword = async () => {
    // console.log(pwdv, confirmPwdv)

    try {
      const resp = await update_password(pwdv, confirmPwdv);
      if (resp) {
        setPwdErr(["Succesfully Updated", "green"]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logOut();
      }
      console.log(error?.response?.data?.detail);
      setPwdErr([error?.response?.data?.message, "red"]);
      // console.log(error.response.data.detail)
    }
    // if (pwdv !== password) {
    //   setPwdErr(["Incorrect Password", "red"]);
    // } else if (confirmPwdv.length <= 5) {
    //   setPwdErr(["Invalid Password", "red"]);
    // } else {
    //   setPwdErr(["Succesfully Updated", "green"]);
    // }
  };

  return (
    <DocumentTitle title="Newdaawn | Settings">
      <div className="settings">
        <Head showMenu={showmenu} />
        {browserWidth > 0 && <Menubar showMenu={ShowMenu} />}
        <div className="settings-container">
          <div className="settings-head">Settings</div>
          <div className="settings-cart">
            <div className="settings-profile">
              <div className="settings-profile-name">
                <span>Edit Profile </span>
                <span className="far fa-address-card"></span>
              </div>
              <label
                htmlFor="profile-picture"
                className="settings-profile-picture"
              >
                <span className="fa-solid fa-pen"></span>
                <div></div>
                <img src={user.image} alt="" />
                <input
                  type="file"
                  name="profile-picture"
                  id="profile-picture"
                />
              </label>
              <div className="settings-profile-form">
                <div className="settings-profile-form-param">
                  <WithdrawCard
                    name={"Fullname"}
                    placeholder={"John Doe"}
                    setValue={fullname}
                    value={user.fullname}
                  />
                  <WithdrawCard
                    name={"Email Address"}
                    placeholder={"user@example.com"}
                    type={"email"}
                    setValue={mail}
                    value={user.email}
                    readonly={true}
                  />
                  <WithdrawCard
                    name={"Phone"}
                    placeholder={"0123456789"}
                    type={"number"}
                    setValue={ssn}
                    value={user.phone}
                  />
                </div>
                <div className="settings-profile-btn">
                  <div
                    className="settings-profile-err"
                    style={{ color: err[1] }}
                  >
                    {err[0]}
                  </div>
                  <div className="settings-profile-submit" onClick={submit}>
                    Update
                  </div>
                </div>
              </div>
            </div>
            <div className="settings-reset">
              <div className="settings-profile-name">
                <span>Reset Password</span>
                <span className="fas fa-user-lock"></span>
              </div>
              <div className="settings-profile-form">
                <div className="settings-profile-form-param">
                  <WithdrawCard
                    name={"Current Password"}
                    type={"password"}
                    setValue={pwd}
                    value={""}
                  />
                  
                  <WithdrawCard
                    name={"New Password"}
                    type={"password"}
                    setValue={confirmPwd}
                    value={""}
                  />
                </div>
                <div className="settings-profile-btn">
                  <div
                    className="settings-profile-err"
                    style={{ color: pwdErr[1] }}
                  >
                    {pwdErr[0]}
                  </div>
                  <div
                    className="settings-profile-submit"
                    onClick={updatePassword}
                  >
                    Update
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}
