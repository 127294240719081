import { useEffect, useRef, useState } from "react";
import "./menubar.scss";
import { DashboardHead } from "../../pages/auth/head";
export default function Head({ showMenu }) {
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [menuState, setMenuState] = useState(false);
  const menuElement = useRef(null);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setBrowserWidth(window.innerWidth);
    });
  }, [browserWidth]);

  const clickedMenu = (e) => {
    showMenu(!menuState);
    setMenuState(!menuState);
  };
  const customBlur = (e) => {
    if (
      menuElement.current &&
      e.target !== menuElement.current &&
      !menuElement.current.contains(e.target)
    ) {
      showMenu(false);
      setMenuState(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", customBlur);

    return () => {
      document.removeEventListener("click", customBlur);
    };
  }, []);

  return (
    <div className="dashboardHead">
      {browserWidth < 1240 && (
        <div ref={menuElement} className="headMenuBar" onClick={clickedMenu}>
          <span className="material-symbols-outlined">menu</span>
        </div>
      )}
      <DashboardHead />
    </div>
  );
}
