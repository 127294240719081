import "./auth.scss";
import DocumentTitle from "react-document-title";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  passwordMatch,
  validateEmail,
  stringLength,
  validFullName,
} from "../../utilities";
import { registerUser } from "../../services/api";
import { AuthHead } from "./head";

export default function Signup() {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [registerData, setRegisterData] = useState({
    username: " ",
    email: " ",
    fullname: " ",
    phone: " ",
    password: " ",
    confirm_password: " ",
  });

  const formDataKeyUp = (key, value) => {
    setRegisterData({ ...registerData, [key]: value });
  };

  const navigatePath = (path) => {
    navigate(path);
  };
  const register = async () => {
    try {
      console.log("first")
      // username len
      if (!stringLength(registerData?.username, 6)) {
        setErrMsg("Username must be minimum of 6 letters");
      }
      // fullname  len
      else if (!validFullName(registerData?.fullname)) {
        setErrMsg("input a valid Full Name e.g. John Doe)");
      }
      // validate email
      else if (!validateEmail(registerData?.email)) {
        setErrMsg("Invalid Email Address");
      }
      //phone
      else if (!stringLength(registerData?.phone, 9)) {
        setErrMsg("Input a valid phone number");
      }
      //password
      else if (!stringLength(registerData?.password, 6)) {
        setErrMsg("password must be minimum of 6 characters");
      }
      // password match
      else if (
        !passwordMatch(registerData?.password, registerData?.confirm_password)
      ) {
        setErrMsg("Password doesn't match");
      } else {
        let resp = await registerUser(registerData);
        if (resp.status) {
          setErrMsg("User Registered");
        }
      }
    } catch (error) {
      setErrMsg(error.response.data.message);
    }
  };

  // const up
  return (
    <DocumentTitle title="Newdaawn | Signup">
      <div className="body">
        <div class="auth_container">
          <AuthHead />
          <div class="formContainer">
            <div class="formHead">Create Account</div>
            <form action="" class="form">
              <div class="err">{errMsg}</div>
              <label id="formItem" class="username">
                <div>Username</div>
                <div class="input">
                  <span class="material-symbols-outlined">account_circle</span>
                  <input
                    title="Enter a unique Username"
                    type="text"
                    required
                    minlength="5"
                    name="username"
                    id="uname"
                    onKeyUp={(e) => {
                      formDataKeyUp("username", e.target.value);
                    }}
                  />
                </div>
              </label>
              <label id="formItem" class="fullname">
                <div>Fullname</div>
                <div class="input">
                  <span class="material-symbols-outlined">person_check</span>
                  <input
                    title="Enter your Legal Fullname"
                    type="text"
                    required
                    minlength="5"
                    name="fullname"
                    id="fname"
                    onKeyUp={(e) => {
                      formDataKeyUp("fullname", e.target.value);
                    }}
                  />
                </div>
              </label>
              <label id="formItem" class="email">
                <div>Email</div>
                <div class="input">
                  <span class="material-symbols-outlined">mail</span>
                  <input
                    title="Enter your Email Address"
                    type="email"
                    required
                    name="mail"
                    id="mail"
                    onKeyUp={(e) => {
                      formDataKeyUp("email", e.target.value);
                    }}
                  />
                </div>
              </label>
              <label id="formItem" class="phone">
                <div>Phone Number</div>
                <div class="input">
                  <span class="material-symbols-outlined">phone</span>
                  <input
                    title="Enter your Phone Number"
                    type="number"
                    minlength="8"
                    required
                    name="phone_number"
                    id="phone"
                    onKeyUp={(e) => {
                      formDataKeyUp("phone", e.target.value);
                    }}
                  />
                </div>
              </label>
              <label id="formItem" class="password">
                <div>Password</div>
                <div class="input">
                  <span class="material-symbols-outlined">lock</span>
                  <input
                    type="password"
                    name="password"
                    required
                    minlength="5"
                    id="pwd"
                    onKeyUp={(e) => {
                      formDataKeyUp("password", e.target.value);
                    }}
                  />
                </div>
              </label>
              <label id="formItem" class="password">
                <div>Confirm Password</div>
                <div class="input">
                  <span class="material-symbols-outlined">lock</span>
                  <input
                    type="password"
                    name="password"
                    required
                    minlength="5"
                    id="confirmPwd"
                    onKeyUp={(e) => {
                      formDataKeyUp("confirm_password", e.target.value);
                    }}
                  />
                </div>
              </label>
              <div class="button signup">
                <div onClick={register}>Sign Up</div>
              </div>
              <div class="alt">
                Already have an account?
                <span
                  onClick={() => {
                    navigatePath("/login");
                  }}
                >
                  <span>Login</span>
                </span>
              </div>
              <div class="copyright">
                &copy; Copyright 2023 All Right Reserved
              </div>
            </form>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}
