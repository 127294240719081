export const AuthHead = () => {
  return (
    <div className="head-auth">
      <img
        src={process.env.PUBLIC_URL + "/assets/stamp.png"}
        alt="Newdaawn Logo"
      />
    </div>
  );
};
export const DashboardHead = () => {
  return (
    <div className="head-dashboard">
      <img
        src={process.env.PUBLIC_URL + "/assets/header_logo.png"}
        alt="Newdaawn Logo"
      />
    </div>
  );
};
