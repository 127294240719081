import "./withdraw.scss";
import { useEffect, useRef, useState } from "react";
import Menubar from "../../../components/menubar";
import Head from "../../../components/menubar/head";
import DocumentTitle from "react-document-title";
import Message from "../../../components/tools/Message";
export function Withdraw() {
  const [activeArr, setActiveArr] = useState(["activePayment", "", ""]);
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [ShowMenu, setShowMenu] = useState(false);
  const [err, setErr] = useState(false);


  sessionStorage.setItem("mainmenu", 0);
  if (sessionStorage.getItem("systemmenu")) {
    sessionStorage.removeItem("systemmenu");
  }
  window.addEventListener("resize", () => {
    setBrowserWidth(window.innerWidth);
  });
  const showmenu = (e) => {
    setShowMenu(e);
  };

  const show = () => {
    for (let i = 0; i < 3; i++) {
      details.current.children[i].id = "";
    }
  };

  const bank = () => {
    setActiveArr(["activePayment", "", ""]);
    details.current.scroll({
      left: 0,
      behavior: "smooth",
    });
    show();
    details.current.children[0].id = "show";
  };

  const paypal = () => {
    setActiveArr(["", "activePayment", ""]);
    details.current.scroll({
      left: details.current.children[0].offsetWidth + 70,
      behavior: "smooth",
    });
    show();
    details.current.children[1].id = "show";
  };

  const bitcoin = () => {
    setActiveArr(["", "", "activePayment"]);
    details.current.scroll({
      left: 10000,
      behavior: "smooth",
    });

    show();
    details.current.children[2].id = "show";
  };

  // const bankDetails = useRef(null);
  // const paypalDetails = useRef(null);
  // const bitcoinDetails = useRef(null);
  const details = useRef(null);

  return (
    <DocumentTitle title="Newdaawn | Withdraw">
      <div className="deposit withdraw">
        <Head showMenu={showmenu} />
        {browserWidth > 0 && <Menubar showMenu={ShowMenu} />}
        <div className="deposit-container">
          <div className="deposit-head">Fund Account</div>
          <div className="deposit-types">
            <div className="deposit-types-head">Withdraw To</div>
          </div>
          {err && <Message err={err[0]} color={err[1]} />}
          <div className="deposit-body">
            <div className="deposit-amount">
              <div>Enter Amount ($)</div>
              <input type="number" name="amount" id="deposit-amount"/>
            </div>
            <div className="deposit-details" ref={details}>
              <div id="show" className="deposit-bank">
                <WithdrawCard name={"Wallet Address"} type={"text"} />
                <div className="withdraw-param">
                  <div className="withraw-name">Wallet Type</div>
                  <div className="withdraw-details">
                    <select name="" id="">
                      <option value="">Bitcoin</option>
                      <option value="">Etheruem</option>
                      <option value="">USDT (TRC20)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="deposit-submit">
              <div className="deposit-submit-btn" onClick={()=> { setTimeout(() => {
                setErr(['Withdraw Request Sent Succesfully','green']); setTimeout(() => {
                  window.location.reload()
                }, 600)
              }, 300);}} >Request Withdraw</div>
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}

export function WithdrawCard({ name, type, placeholder, setValue, value, readonly = false}) {
  const [uservalue, setUservalue] = useState();
  // useEffect(() => {
  //   setUservalue(value)
  // }, [])
  
  const output = (e) => {
    setValue(e.target);
    setUservalue(e.target.value);
  };
  return (
    <div className="withdraw-param">
      <div className="withraw-name">{name}</div>
      <div className="withdraw-details">
        <input
          type={type}
          autoCapitalize="words"
          placeholder={(placeholder && placeholder) || `Enter your ${name}`}
          onChange={output}
          value={uservalue || value}
          readOnly={readonly}
        />
      </div>
    </div>
  );
}
