import { useEffect, useRef, useState } from "react";
import "./transactions.scss";
import Head from "../../../components/menubar/head";
import Menubar from "../../../components/menubar";
import { CustomTable } from "../../../components/customTable";
import {transaction_history} from "../../../services/api"

export default function Transactions() {
  const [depositList, setDepositList] = useState([])
  const [withdrawList, setWithdrawList] = useState([])

  useEffect(() => {
     load_data()
  }, [])

  const load_data = async() => {
    let data = await transaction_history() 
    setDepositList(data?.deposits)
    setWithdrawList(data?.withdraws)
  }

  // const Others = [["#00D400", "Bonus", "Nov 15", "$320.00", "Successful"]];
  // const DepositList = [
  //   ["#00D400", "Bank", "Nov 15", "$320.00", "Successful"],
  //   ["#00D400", "Bank", "Oct 28", "$120.00", "Successful"],
  //   ["#F60000", "Paypal", "Oct 21", "$250.00", "Failed"],
  //   ["#00D400", "Crpto", "Nov 15", "$320.00", "Successful"],
  //   ["#00D400", "Crypto", "Oct 28", "$120.00", "Successful"],
  //   ["#F60000", "Bank", "Oct 21", "$250.00", "Failed"],
  // ];
  // const WithdrawList = [
  //   ["#F60000", "Bank", "Nov 2", "$500.00", "Failed"],
  //   ["#00D400", "Crypto", "Oct 5", "$400.00", "Succesful"],
  //   ["#F60000", "Crypto", "Nov 2", "$500.00", "Failed"],
  // ];
  sessionStorage.setItem("mainmenu", 2);
  if (sessionStorage.getItem("systemmenu")) {
    sessionStorage.removeItem("systemmenu");
  }
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [ShowMenu, setShowMenu] = useState(false);
  window.addEventListener("resize", () => {
    setBrowserWidth(window.innerWidth);
  });
  const showmenu = (e) => {
    setShowMenu(e);
  };
  const [tableindex, setTableindex] = useState(0);
  const table_nav = useRef(null);
  useEffect(() => {
    if (table_nav.current) {
      for (let j = 0; j < 2; j++) {
        table_nav.current.children[j].id = "";
      }
      table_nav.current.children[tableindex].id = "active_nav";
      if (tableindex > 0) {
        table_nav.current.parentElement.lastChild.id = "table_rad";
      } else {
        table_nav.current.parentElement.lastChild.id = "";
      }

      for (let i = 0; i < 2; i++) {
        table_nav.current.children[i].addEventListener("click", () => {
          setTableindex(i);
        });
      }
    }
  }, [tableindex]);

  return (
    <div className="transactions">
      <Head showMenu={showmenu} />
      {browserWidth > 0 && <Menubar showMenu={ShowMenu} />}
      <div className="transactions-container">
        <div className="transactions-head">Transactions History</div>
        <div className="transactions-body">
          <div className="transactions-navigation" ref={table_nav}>
            <div>Deposits</div>
            <div>Withdrawals</div>
            {/* <div>Others</div> */}
          </div>
          <div className="transactions-table">
            <div className="transactions-table-container">
              {
              // tableindex === 2 ? (
              //   <>
              //     <CustomTable
              //       type="head"
              //       param={["Method", "Date", "Amount", "Status"]}
              //       tableLength={Others.length}
              //       tableName={"Other Transaction History"}
              //     />
              //     {Others.map((data, index) => (
              //       <CustomTable key={index} type="body" param={data} />
              //     ))}
              //   </>
              // ) :
              tableindex === 0 ? (
                <>
                  <CustomTable
                    type="head"
                    param={["Method", "Date", "Amount", "Status"]}
                    tableLength={depositList.length}
                    tableName={"Deposit History"}
                  />
                  {depositList.map((data, index) => (
                    <CustomTable key={index} type="body" param={data} />
                  ))} 
                </>
              ) : tableindex === 1 ? (
                <>
                  <CustomTable
                    type="head"
                    param={["Method", "Date", "Amount", "Status"]}
                    tableLength={withdrawList.length}
                    tableName={"Withdrawal History"}
                  />
                  {withdrawList.map((data, index) => (
                    <CustomTable key={index} type="body" param={data} />
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
