import "./deposit.scss";
import { useEffect, useRef, useState } from "react";
import Menubar from "../../../components/menubar";
import Head from "../../../components/menubar/head";
import DocumentTitle from "react-document-title";
import Message from "../../../components/tools/Message";
import { deposit } from "../../../services/api";
export function Deposit() {
  const [activeArr, setActiveArr] = useState(["activePayment", "", ""]);
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [ShowMenu, setShowMenu] = useState(false);
  const [hash, setHash] = useState("");
  const [amount, setAmount] = useState("");
  const [screenshot, setScreenshot] = useState("");
  const [message, setMessage] = useState({text: "", color: "", show: false});
  const [paymentMethod, setPaymentMethod] = useState("Bitcoin");

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (paymentMethod === "Bitcoin") {
        bank();
      } else if (paymentMethod === "Ethereum") {
        paypal();
      } else {
        bitcoin();
      }
    });
  }, [paymentMethod]);

  sessionStorage.setItem("mainmenu", 0);
  if (sessionStorage.getItem("systemmenu")) {
    sessionStorage.removeItem("systemmenu");
  }
  window.addEventListener("resize", () => {
    setBrowserWidth(window.innerWidth);
  });
  const showmenu = (e) => {
    setShowMenu(e);
    console.log(e);
  };

  const show = () => {
    for (let i = 0; i < 3; i++) {
      details.current.children[i].id = "";
    }
  };

  const bank = () => {
    setPaymentMethod("Bitcoin");
    setActiveArr(["activePayment", "", ""]);
    details.current.scroll({
      left: 0,
      behavior: "smooth",
    });
    show();
    details.current.children[0].id = "show";
  };

  const paypal = () => {
    setPaymentMethod("Ethereum");
    setActiveArr(["", "activePayment", ""]);
    details.current.scroll({
      left: details.current.children[0].offsetWidth + 70,
      behavior: "smooth",
    });
    show();
    details.current.children[1].id = "show";
  };

  const bitcoin = () => {
    setPaymentMethod("USDT (TRC20)");
    setActiveArr(["", "", "activePayment"]);
    details.current.scroll({
      left: 10000,
      behavior: "smooth",
    });
    show();
    details.current.children[2].id = "show";
  };

  const fileChange = (e) => {
    const fileName = e.target.value.split("\\").pop();
    setScreenshot(fileName);
  };

  const hashDataKeyUp = (value) => {
    setHash(value);
  };

  const details = useRef(null);

  const send_deposit = async () => {
    try {
      const resp = await deposit(amount, paymentMethod, hash)
      console.log(resp.data.message)
      setMessage({text: resp.data.message, show: true, color: "green"})
    } catch (error) {
      setMessage({text: error.response.data.message, show: true, color: "red"})
    }
  };

  return (
    <DocumentTitle title="Newdaawn | Deposit">
      <div className="deposit">
        <Head showMenu={showmenu} />
        {browserWidth > 0 && <Menubar showMenu={ShowMenu} />}
        <div className="deposit-container">
          <div className="deposit-head">Fund Account</div>
          <div className="deposit-types">
            <div className="deposit-types-head">Pay With</div>
            <div className="deposit-types-container">
              <div
                className="deposit-types-icon"
                id={activeArr[0]}
                onClick={bank}
              >
                <span className="fab fa-btc"></span>
              </div>
              <div
                className="deposit-types-icon"
                id={activeArr[1]}
                onClick={paypal}
              >
                <span class="fa-brands fa-ethereum"></span>
              </div>
              <div
                className="deposit-types-icon"
                id={activeArr[2]}
                onClick={bitcoin}
              >
                <span className="fas fa-university"></span>
              </div>
            </div>
          </div>
          {message?.show && 
          <Message err={message?.text} color={message?.color || "green"} />
          }
          <div className="deposit-body">
            <div className="deposit-amount">
              <div>
                <span>Enter Amount</span>
                <span>{paymentMethod}</span>
              </div>
              <input type="number" name="amount" id="deposit-amount" onKeyUp={(e) => { setAmount(e.target.value) }} />
            </div>
            <div className="deposit-details" ref={details}>
              <div id="show" className="deposit-bank">
                <CopyCard
                  name={"Wallet Address"}
                  details={"bc1ql3uwk9gvhxmw5q0mwz62yvsyru3r4h77483x5u"}
                />
                <QrCard link={'https://lorem-general.s3.amazonaws.com/inv-tmp/btc.jpg'}/>
              </div>
              <div className="deposit-bitcoin">
                <CopyCard
                  name={"Wallet Address"}
                  details={"0xc1B79fB47Fe6cfEAdCF83A49E17549e8aa332B8a"}
                />
                <QrCard link={'https://lorem-general.s3.amazonaws.com/inv-tmp/eth.jpg'}/>
              </div>
              <div className="deposit-paypal">
                <CopyCard
                  name={"Wallet Address"}
                  details={"TF9zjmJZkAsrkbJhYoQpVqL78nyjQN5bKZ"}
                />
                <QrCard link={'https://lorem-general.s3.amazonaws.com/inv-tmp/usdt-trc20.jpg'}/>
              </div>
            </div>
            <div className="deposit-submit">
              <div>Enter Transaction Hash</div>
              <input type="text" onKeyUp={(e) => { setHash(e.target.value) }} />
              <div onClick={send_deposit} className="deposit-submit-btn">
                Submit Payment
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}

export function CopyCard({ name, details }) {
  return (
    <div className="deposit-param">
      <span>{name}</span>
      <div className="deposit-copy">
        <span>{details}</span>
        <span className="fas fa-copy"></span>
      </div>
    </div>
  );
}

export const QrCard = ({ link }) => {
  return <div className="deposit-qr">
    <img src={link} alt="QRCode" />
  </div>;
};
